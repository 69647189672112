<template>
  <div>
    <b-card title="Create New Device">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="4">
              <b-form-group label="Model Name">
                <validation-provider
                  #default="{ errors }"
                  name="Model Name"
                  rules="required"
                >
                  <b-form-input
                    v-model="name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter the model name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4" class="brand-select">
              <b-form-group label="Brand">
                <v-select
                  placeholder="Select Brand"
                  v-model="brand"
                  :options="brands"
                  label="name"
                >
                  <template #option="{ name, logo }">
                    <img :src="logo.image" />
                    <span> {{ name }}</span>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col md="4" class="brand-select">
              <b-form-group label="Issues">
                <v-select
                  placeholder="Select Issue(s)"
                  multiple
                  v-model="issues"
                  :options="issuesList"
                  label="name"
                >
                  <template #option="{ name, icon }">
                    <img :src="icon" />
                    <span> {{ name }}</span>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col md="4" class="media-select">
              <img v-if="image" :src="image" />
              <MediaUploader
                @mediaSelected="handleModelImageChange"
                :isMultiple="false"
                text="Choose Device Image"
              />
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>

    <b-card v-if="issues.length">
      <b-row>
        <b-col cols="2">
          <h6>Issue</h6>
        </b-col>
        <b-col cols="2">
          <h6>Price Details</h6>
        </b-col>
        <b-col cols="2" class="text-center">
          <h6>Warranty</h6>
        </b-col>
        <b-col cols="5">
          <h6>Warranty Details</h6>
        </b-col>
      </b-row>
      <div class="issue-item" v-for="issue in issues" :key="issue.issue">
        <b-row>
          <b-col cols="2" class="align-self-center">
            <div class="issue-details">
              <div class="issue-icon">
                <img :src="issue.icon" alt="" />
              </div>
              <div class="issue-name">
                <h5>{{ issue.name }}</h5>
              </div>
            </div>
          </b-col>
          <b-col cols="2">
            <b-form-group label="Price" label-for="issue-price">
              <b-form-input
                v-model="issue.price"
                id="issue-price"
                type="number"
                required
                placeholder="Price"
              />
            </b-form-group>
            <b-form-group label="Strike Through Price" label-for="issue-price">
              <b-form-input
                v-model="issue.strikeThroughPrice"
                id="issue-strike-price"
                type="number"
                placeholder="Strike Through Price"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="2"
            class="d-flex align-self-center text-center justify-content-center"
          >
            <div>
              <b-form-checkbox
                :id="`is-warranty-available-${issue.issue}`"
                :name="`is-warranty-available-${issue.issue}`"
                :value="true"
                v-model="issue.warranty.isAvailable"
              >
              </b-form-checkbox>
            </div>
          </b-col>
          <b-col cols="5" class="align-self-center">
            <b-row>
              <b-col>
                <b-form-group label="Warranty Price" label-for="issue-price">
                  <b-form-input
                    :disabled="!issue.warranty.isAvailable"
                    v-model="issue.warranty.price"
                    id="hi-number"
                    type="number"
                    required
                    placeholder="Price"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Warranty Period" label-for="issue-price">
                  <b-form-input
                    :disabled="!issue.warranty.isAvailable"
                    v-model="issue.warranty.period"
                    id="hi-number"
                    type="number"
                    required
                    placeholder="Period"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Period Value" label-for="issue-price">
                  <b-form-select
                    v-model="issue.warranty.periodValue"
                    :options="periodValueOptions"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="1" class="align-self-center">
            <div>
              <b-form-checkbox
                :id="`is-private-issue-${issue.issue}`"
                :name="`is-private-issue-${issue.issue}`"
                :value="true"
                v-model="issue.isPrivate"
              >
                <feather-icon
                  :icon="issue.isPrivate ? 'EyeOffIcon' : 'EyeIcon'"
                ></feather-icon>
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-row>
      <b-col cols="12" class="text-center">
        <b-button
          :disabled="!isDataValid"
          @click="createModel"
          variant="primary"
        >
          <b-spinner small v-if="creatingModel"></b-spinner> Create
          Model</b-button
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BLink,
  BBreadcrumb,
  BBreadcrumbItem,
  BButton,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormCheckbox,
  BFormSelect,
  BFormGroup,
  BSpinner,
  BForm,
} from "bootstrap-vue";
import MediaUploader from "@/components/MediaUploader.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import vSelect from "vue-select";
import axios from "axios";
import { getUserToken } from "@/auth/utils";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BLink,
    BBreadcrumb,
    BBreadcrumbItem,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormCheckbox,
    BFormSelect,
    BFormGroup,
    BSpinner,
    MediaUploader,
    ValidationProvider,
    ValidationObserver,
    BForm,
    vSelect,
  },
  data() {
    return {
      required,
      name: "",
      brand: "",
      image: "",
      issues: [],
      brands: [],
      issuesList: [],
      periodValueOptions: [
        { value: null, text: "Select warranty period value" },
        { value: "d", text: "Day(s)" },
        { value: "m", text: "Month(s)" },
        { value: "y", text: "Year(s)" },
      ],
      creatingModel: false,
    };
  },
  computed: {
    isDataValid() {
      if (!this.name || !this.brand || !this.issues.length) {
        return false;
      }
      return true;
    },
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    getOptions() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/model/get-options`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          const { brands, issues } = response.data;
          this.brands = brands;
          let issuesDataUpdated = issues.map((issue) => {
            return {
              issue: issue._id,
              price: 0,
              strikeThroughPrice: 0,
              icon: issue.icon,
              name: issue.name,
              isPrivate: false,
              warranty: {
                isAvailable: false,
                price: 0,
                period: 0,
                periodValue: "m",
              },
            };
          });
          this.issuesList = issuesDataUpdated;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
    createModel() {
      if (!this.isDataValid) {
        return;
      }
      let name = this.name;
      let brand = this.brand ? this.brand._id : null;
      let media = {
        image: this.image,
      };
      let issues = this.issues;
      this.creatingModel = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/model/create`,
          {
            name,
            brand,
            media,
            issues,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.creatingModel = false;
          this.name = "";
          this.brand = "";
          this.image = "";
          this.issues = [];
          this.makeToast("success", "Success", `Model created successfully!`);
        })
        .catch((error) => {
          this.creatingModel = false;
          this.makeToast("danger", "Error", `Error creating device model!`);
        });
    },
    handleModelImageChange(imageData) {
      if (imageData.status) {
        this.image = imageData.media.src;
      }
    },
  },
  mounted() {
    this.getOptions();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.brand-select {
  img {
    max-width: 20px;
  }
}

.issue-details {
  display: flex;
  align-items: center;
}
.issue-details img {
  max-width: 70%;
}
.issue-details h6,
.issue-details h5 {
  margin: 0 0 0 0px;
}
.issue-item {
  margin: 12px 0;
  border-bottom: 1px dashed #000;
}
.strike-price-input {
  margin-top: 2px;
}
.media-select img {
  max-width: 30%;
}
.media-select {
  text-align: left;
}
</style>
